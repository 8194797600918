import React from 'react';

function WeatherButtons({ onForecastSelect }) {
	return (
		<div className="button-container">
			<button onClick={() => onForecastSelect('next hour')}>Next hour</button>	
			<button onClick={() => onForecastSelect('next 3 hours')}>Next 3 hours</button>
			<button onClick={() => onForecastSelect('day')}>Day</button>
		</div>
	)
}

export default WeatherButtons;