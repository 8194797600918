import axios from "axios";

const apiKey = "018f34a81dbd8c3140a0c5c3469191ef";

export const fetchWeatherData = async (lat, lon, period) => {
  try {
    const response = await axios.get(
      `https://api.openweathermap.org/data/3.0/onecall`,
      {
        params: {
          lat: lat,
          lon: lon,
          exclude: "minutely",
          appid: apiKey,
        },
      }
    );

    console.log(response.data);
    console.log(`Getting weather for ${period}`);

    const getRainLikelihood = (humidity, rain) => {
	  if (rain) return "rain";
      if (humidity > 50 && humidity < 60) return "a small chance of rain";
      if (humidity >= 60) return "a chance of rain";
      return;
    };

    const getTemperature = (t) => {
      const temp_deg_c = Math.round(t - 273.15);
      //const temp_deg_f = "";
      return temp_deg_c;
    };

    const getWeatherSummary = (response, period) => {
      const temperature = getTemperature(response.data.current.feels_like);
	  const rain = response.data.current.rain ? response.data.current.rain : "";
      const rainLikelihood = getRainLikelihood(response.data.current.humidity, rain);

      if (period === "next hour") {
        return `The weather for the ${period} will be ${response.data.current.weather[0].description}. 
					The temperature will be around ${temperature}°C with ${rainLikelihood}.`;
      }
      if (period === "next 3 hours") {
        return `The weather for the ${period} will be ${response.data.hourly[1].weather[0].description},
		${response.data.hourly[2].weather[0].description}, and ${response.data.hourly[3].weather[0].description}, 
			with temperatures around ${getTemperature(response.data.hourly[2].feels_like)}°C.`;
      }
      return `${
        response.data.daily[0].summary
      }. The temperature will range from ${getTemperature(
        response.data.daily[0].temp.min
      )} to ${getTemperature(response.data.daily[0].temp.max)}°C.`;
    };
    return getWeatherSummary(response, period);
  } catch (error) {
    console.error("Error fetching weather:", error);
    return "Failed to fetch weather";
  }
};
