import React, { useState, useEffect } from "react";
import "./App.css";
import WeatherButtons from "./WeatherButtons";
import { fetchWeatherData } from "./WeatherAPI";
import { convertTextToSpeech } from "./TextToSpeech";

function App() {
  const [location, setLocation] = useState({
    lat: null,
    lon: null,
  });

  useEffect(() => {
    // Check for Geolocation API support
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleForecastSelect = async (forecastType) => {
    console.log("Selected:", forecastType);
    if (location.lat && location.lon) {
      const description = await fetchWeatherData(
        location.lat,
        location.lon,
        forecastType
      );
      console.log(description);

      const speechUrl = await convertTextToSpeech(description);
      if (speechUrl) {
        new Audio(speechUrl).play();
      }
    } else {
      console.error("Location data not available");
    }
  };

  return (
    <div className="App">
      <h1>Read Me the Weather</h1>
      <WeatherButtons onForecastSelect={handleForecastSelect} />
    </div>
  );
}

export default App;
