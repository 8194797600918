export const convertTextToSpeech = async (
  text,
  languageCode = "en-gb",
  voiceName = "en-GB-Standard-A",
  ssmlGender = "FEMALE"
) => {
  const params = new URLSearchParams({
    text: text,
    languageCode: languageCode,
    voiceName: voiceName,
    ssmlGender: ssmlGender,
  });

  //const res = await fetch(`http://localhost:3001/speak?${params.toString()}`);
  const res = await fetch(`api/speak?${params.toString()}`);

  if (res.status === 200) {
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    return url;
  }

  return null;
};
